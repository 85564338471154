import styles from './Alert.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type AlertProps = {
  variant?: 'success' | 'danger' | 'warning';
  className?: string;
  children: React.ReactNode;
  dataTestId?: string;
};

const Alert = ({ variant, className, dataTestId, children }: AlertProps) => {
  return (
    <div
      data-testid={dataTestId}
      className={mergeClassNames([
        styles.container,
        variant && styles[variant],
        className,
      ])}
    >
      {children}
    </div>
  );
};

export default Alert;
